<template>
    <div class="chartset">
        <van-nav-bar :title="$t('Mt4ApiMenu.settings')" :left-text="$t('toback')" left-arrow
                     @click-left="back"/>
        <div class="page member">

            <div class="cblock">
                <van-radio-group v-model="klineType">
                    <van-cell-group v-for="(item,index) in chartTypes" :key="item.id">
                        <van-cell :title="item.text" clickable @click="setKlineType(item)">
                            <template #right-icon>
                                <van-radio :name="item.id" checked-color="#13b5b1" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
            </div>

            <div class="cblock margin-top">
                <van-cell center title="Dark Theme">
                    <template #right-icon>
                        <van-switch v-model="themeChecked" size="24"
                                    active-color="#13b5b1" inactive-color="#dcdee0"
                                    @change="setKlineTheme" />
                    </template>
                </van-cell>
            </div>
        </div>




    </div>
</template>
<script>
    export default {
        data() {
            return {
                klineType: 'candle_solid',
                chartTypes: [
                    { id: 'candle_solid', text: this.$t('KLineChart.candleSolid') },
                    { id: 'candle_stroke', text: this.$t('KLineChart.candleSStroke') },
                    { id: 'candle_up_stroke', text: this.$t('KLineChart.candleUpStroke') },
                    { id: 'candle_down_stroke', text: this.$t('KLineChart.candleDownStroke') },
                    { id: 'ohlc', text: this.$t('KLineChart.candleOhlc') },
                    { id: 'area', text: this.$t('KLineChart.candleArea') }
                ],
                themeChecked: false,
                defaultKline: {
                    type: "candle_solid",
                    theme: "light"
                }

            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", false);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");
            let cache = localStorage.getItem('klineChart');
            if (cache != null) {
                let json = JSON.parse(cache);
                this.klineType = json.type;
                this.themeChecked = json.theme == "dark" ? true : false;

                this.defaultKline.type = json.type;
                this.defaultKline.theme = json.theme;
            }
        },
        created() {

        },
        beforeDestroy() { },
        methods: {
            back() {
                localStorage.setItem('klineChart', JSON.stringify(this.defaultKline));
                this.$router.back();
            },
            setKlineType(ts) {
                this.klineType = ts.id;
                this.defaultKline.type = ts.id;
                this.$store.commit('setKLineType', ts.id);
            },
            setKlineTheme() {
                if (this.themeChecked) {
                    this.defaultKline.theme = "dark";
                } else {
                    this.defaultKline.theme = "light";
                }
            }
        },
    };
</script>


<style>
    #app {
        background-color: #f7f7f7;
    }

    .chartset .van-nav-bar__text, .chartset .van-nav-bar__title {
        color: #fff
    }


    .page {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .cblock {
        margin: 0 10px;
    }



    .margin-top {
        margin-top: 0.5rem;
    }

    .cblock:last-child {
        padding-bottom: 0.5rem;
    }

    .chartset .van-nav-bar__content {
        background-color: #13b5b1
    }

    .chartset .van-nav-bar, .chartset .van-icon, .chartset .van-nav-bar__text {
        color: #fff
    }
</style>
<style scoped>
    div[aria-checked=false].van-radio {
        display: none;
    }
</style>